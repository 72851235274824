import React from "react"

const Footer = () => {
  return (
    <div className="container py-12 md:flex md:items-center">
        <img src={'/aldir-blanc.jpg'} alt="Logo" />
    </div>
  )
}

export default Footer